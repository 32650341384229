import { Component, h } from "preact";
import { Router, RouterOnChangeArgs } from "preact-router";
import AsyncRoute from "preact-async-route";

import Header from "./shared/header/header";
import Footer from "./shared/footer/footer";

const prerenderUrls = require("../../prerender-urls.json");

declare const window;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
if ((module as any).hot) {
  // tslint:disable-next-line:no-var-requires
  require("preact/debug");
}

if (typeof window !== "undefined") {
  window.ga("create", process.env.PREACT_APP_GOOGLE_ANALYTICS_ID, "auto");
}

export class App extends Component {
  handleRoute(e: RouterOnChangeArgs) {
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
      let urlObj = null;
      for (let i = 0; i < prerenderUrls.length; i++) {
        const urlWithSlash = prerenderUrls[i].url + "/";
        if (prerenderUrls[i].url === e.url || urlWithSlash === e.url) {
          urlObj = prerenderUrls[i];
        }
      }
      if (!!urlObj) {
        document.title = urlObj.title;
      }
      window.ga("set", "page", e.url);
      window.ga("set", "location", window.location.href);
      window.ga("send", {
        hitType: "pageview",
        page: e.url,
        location: window.location.href
      });
    }
  }

  getHome() {
    return import("../routes/home/home").then(module => module.default);
  }

  getPricing() {
    return import("../routes/pricing/pricing").then(module => module.default);
  }

  getApi() {
    return import("../routes/api/api").then(module => module.default);
  }

  getBlog() {
    return import("../routes/blog/blog").then(module => module.default);
  }

  getOurServices() {
    return import("../routes/our-services/our-services").then(
      module => module.default
    );
  }

  getFaq() {
    return import("../routes/faq/faq").then(module => module.default);
  }

  getAboutUs() {
    return import("../routes/about-us/about-us").then(module => module.default);
  }

  getContactUs() {
    return import("../routes/contact/contact").then(module => module.default);
  }

  getPrivacyPolicy() {
    return import("../routes/legal/privacy-policy/privacy-policy").then(
      module => module.default
    );
  }

  getTermsAndConditions() {
    return import("../routes/legal/terms/terms").then(module => module.default);
  }

  getAntiSpam() {
    return import("../routes/legal/anti-spam/anti-spam").then(
      module => module.default
    );
  }

  getBlogPost(name: string) {
    return import(`../routes/blog/posts/${name}`).then(
      module => module.default
    );
  }

  getPageNotFound() {
    return import("../routes/404-page/404-page").then(module => module.default);
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.src =
      "https://cdn.jsdelivr.net/npm/cookieconsent@3/build/cookieconsent.min.js";
    script["data-cfasync"] = false;
    script.async = true;

    script.onload = function() {
      if (typeof window !== "undefined") {
        window["cookieconsent"].initialise({
          palette: {
            popup: {
              background: "#eaf7f7",
              text: "#5c7291"
            },
            button: {
              background: "#56cbdb",
              text: "#ffffff"
            }
          },
          theme: "classic",
          position: "bottom-left",
          content: {
            href: "https://removebounce.com/privacy-policy"
          }
        });
      }
    };
    document.body.appendChild(script);
  }

  render() {
    return (
      <div id="app">
        <Header />
        <Router onChange={this.handleRoute}>
          <AsyncRoute path="/" getComponent={this.getHome} />
          <AsyncRoute path="/pricing" getComponent={this.getPricing} />
          <AsyncRoute path="/email-cleaning-api" getComponent={this.getApi} />
          <AsyncRoute
            path="/email-list-cleaning-services"
            getComponent={this.getOurServices}
          />
          <AsyncRoute path="/faqs" getComponent={this.getFaq} />
          <AsyncRoute path="/about-us" getComponent={this.getAboutUs} />
          <AsyncRoute path="/contact" getComponent={this.getContactUs} />
          <AsyncRoute path="/blog" getComponent={this.getBlog} />
          <AsyncRoute
            path="/blog/9-ways-to-reduce-email-bounce-rate"
            getComponent={() =>
              this.getBlogPost("9-ways-to-reduce-email-bounce-rate")
            }
          />
          <AsyncRoute
            path="/blog/3-reasons-to-clean-your-email-list"
            getComponent={() =>
              this.getBlogPost("3-reasons-to-clean-your-email-list")
            }
          />
          <AsyncRoute
            path="/blog/bulk-email-list-cleaning-service"
            getComponent={() =>
              this.getBlogPost("bulk-email-list-cleaning-service")
            }
          />
          <AsyncRoute
            path="/blog/email-address-verifier"
            getComponent={() => this.getBlogPost("email-address-verifier")}
          />
          <AsyncRoute
            path="/blog/hard-bounce-email-removal-system"
            getComponent={() =>
              this.getBlogPost("hard-bounce-email-removal-system")
            }
          />
          <AsyncRoute
            path="/blog/online-bulk-email-verification-service"
            getComponent={() =>
              this.getBlogPost("online-bulk-email-verification-service")
            }
          />
          <AsyncRoute
            path="/blog/remove-hard-bounces"
            getComponent={() => this.getBlogPost("remove-hard-bounces")}
          />
          <AsyncRoute
            path="/blog/what-is-an-email-address-verifier-service-and-how-you-can-use-it-to-improve-your-deliverability-and-ctr"
            getComponent={() =>
              this.getBlogPost(
                "what-is-an-email-address-verifier-service-and-how-you-can-use-it-to-improve-your-deliverability-and-ctr"
              )
            }
          />
          <AsyncRoute
            path="/blog/what-is-email-address-verification-and-how-you-can-benefit-from-using-it"
            getComponent={() =>
              this.getBlogPost(
                "what-is-email-address-verification-and-how-you-can-benefit-from-using-it"
              )
            }
          />
          <AsyncRoute
            path="/blog/what-is-email-checker-and-why-do-all-businesses-need-to-use-it"
            getComponent={() =>
              this.getBlogPost(
                "what-is-email-checker-and-why-do-all-businesses-need-to-use-it"
              )
            }
          />
          <AsyncRoute
            path="/blog/why-do-all-businesses-need-to-use-email-verification"
            getComponent={() =>
              this.getBlogPost(
                "why-do-all-businesses-need-to-use-email-verification"
              )
            }
          />
          <AsyncRoute
            path="/blog/why-using-bulk-email-list-cleaning-service"
            getComponent={() =>
              this.getBlogPost("why-using-bulk-email-list-cleaning-service")
            }
          />
          <AsyncRoute
            path="/blog/why-you-need-to-clean-your-email-list"
            getComponent={() =>
              this.getBlogPost("why-you-need-to-clean-your-email-list")
            }
          />
          <AsyncRoute
            path="/blog/how-to-remove-bounce-from-an-email-list"
            getComponent={() => this.getBlogPost("how-to-removebounce")}
          />
          <AsyncRoute
            path="/blog/hard-bounces-vs-soft-bounces-and-how-to-remove-them"
            getComponent={() =>
              this.getBlogPost("hard-bounces-vs-soft-bounces")
            }
          />
          <AsyncRoute
            path="/privacy-policy"
            getComponent={this.getPrivacyPolicy}
          />
          <AsyncRoute
            path="/terms-services"
            getComponent={this.getTermsAndConditions}
          />
          <AsyncRoute path="/anti-spam" getComponent={this.getAntiSpam} />
          <AsyncRoute
            default
            path="/not-found"
            getComponent={this.getPageNotFound}
          />
        </Router>
        <Footer />
      </div>
    );
  }
}

export default App;
