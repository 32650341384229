import { h, Component } from "preact";
import { Link } from "preact-router";

class Footer extends Component {
  render() {
    return (
      <footer class="footer-style-one">
        <div class="footer-upper">
          <div class="auto-container">
            <div class="row clearfix">
              <div class="big-column col-lg-3 col-md-12 col-sm-12 col-xs-12">
                <div class="footer-column">
                  <div class="footer-widget about-widget">
                    <div class="widget-inner">
                      <div class="logo">
                        <Link href="/">
                          <img src="assets/logo.svg" alt="logo remove bounce" />
                        </Link>
                      </div>
                      <div class="copyright">RemoveBounce Ltd &copy;2020, a company registered in England</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="big-column col-lg-9 col-md-12 col-sm-12 col-xs-12">
                <div class="row clearfix">
                  <div class="footer-column col-lg-3 col-md-3 col-sm-6 col-xs-6">
                    <div class="footer-widget links-widget">
                      <div class="widget-inner">
                        <h3>Product</h3>
                        <div class="links">
                          <ul>
                            <li>
                              <Link href="/pricing">Pricing</Link>
                            </li>
                            <li>
                              <Link href="/pricing">Competitors</Link>
                            </li>
                            <li>
                              <Link href="/email-list-cleaning-services">Our Services</Link>
                            </li>
                            <li>
                              <Link href="/email-cleaning-api">API</Link>
                            </li>
                            <li>
                              <a
                                href="https://docs.removebounce.com"
                                target="_blank"
                              >
                                API Docs
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="footer-column col-lg-3 col-md-3 col-sm-6 col-xs-6">
                    <div class="footer-widget links-widget">
                      <div class="widget-inner">
                        <h3>Company</h3>
                        <div class="links">
                          <ul>
                            <li>
                              <Link href="/about-us">About</Link>
                            </li>
                            <li>
                              <Link href="/terms-services">Terms of Use</Link>
                            </li>
                            <li>
                              <Link href="/privacy-policy">Privacy Policy</Link>
                            </li>
                            <li>
                              <Link href="/anti-spam">Anti Spam Policy</Link>
                            </li>
                            <li>
                              <Link href="/contact">Contact</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="footer-column col-lg-3 col-md-3 col-sm-6 col-xs-6">
                    <div class="footer-widget links-widget">
                      <div class="widget-inner">
                        <h3>Resources</h3>
                        <div class="links">
                          <ul>
                            <li>
                              <Link href="/blog">Blog</Link>
                            </li>
                            <li>
                              <Link href="/faqs">FAQs</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="footer-column col-lg-3 col-md-3 col-sm-6 col-xs-6">
                    <div class="footer-widget follow-widget">
                      <div class="widget-inner">
                        <h3>Follow us</h3>
                        <div class="social-links">
                          <ul class="clearfix">
                            <li>
                              <a
                                href="https://www.facebook.com/removebounces/"
                                target="_blank"
                                rel="noreferrer noopener"
                              >
                                <span class="fa fa-facebook-f"></span>
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://twitter.com/RemoveBounce"
                                target="_blank"
                                rel="noreferrer noopener"
                              >
                                <span class="fa fa-twitter"></span>
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://www.instagram.com/removebounce_email_verifier/"
                                target="_blank"
                                rel="noreferrer noopener"
                              >
                                <span class="fa fa-instagram"></span>
                              </a>
                            </li>
                            <li>
                              <a
                                href="http://linkedin.com/company/removebounce"
                                target="_blank"
                                rel="noreferrer noopener"
                              >
                                <span class="fa fa-linkedin"></span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
